import React from 'react'
import styled from 'styled-components'
import { Banner } from './Banner'
import WhyraindropData from '../Data/WhyraindropBannerData.json'

const WhyRainDropBannerWapper = styled.div`
  .containSection {
    display: flex;
    max-width: 1920px;
    margin: auto;
    padding: 130px 96px 50px 96px;
    justify-content: space-between;
    overflow: hidden;
    @media (max-width: 1391px) {
      padding: 130px 20px 50px 20px;
    }
    @media (max-width: 1090px) {
      padding: 80px 15px;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .BannerSectionOne {
    margin-top: 2rem;
    margin-bottom: auto;
    @media (max-width: 1390px) {
      margin-top: auto;
    }
  }
  .BannerHeadingSize {
    max-width: 750px;
    line-height: 1.30435em;
    padding-bottom: 3%;
    font-size: 2.5rem;
  }
  .BannerPara {
    max-width: 650px;
    padding: 10px 0px 0px 0px;
  }
  .BannerImage {
    width: 70%;
    height: auto;
    float: right;
    @media (max-width: 1390px) {
      width: 100%;
      float: unset;
    }
  }
  .whyRaindropListView {
    display: none;
  }
`

export const WhyRainDropBanner = () => {
  return (
    <WhyRainDropBannerWapper>
      {WhyraindropData.map((data, index) => {
        return (
          <div key={index}>
            <Banner
              containSection="containSection"
              BannerHeading={data.heading}
              paraOne={data.paraOne}
              paraTwo={data.paraTwo}
              paraThree={data.paraThree}
              ImagePath={data.ImagePath}
              altName={data.AltName}
              BannerSectionOne="BannerSectionOne"
              BannerSectionTwo="BannerSectionTwo item-2"
              BannerHeadingSize="BannerHeadingSize"
              BannerPara="BannerPara"
              BannerImage="BannerImage"
              UlStyle="whyRaindropListView"
            />
          </div>
        )
      })}
    </WhyRainDropBannerWapper>
  )
}
