import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import { ReuseImgTag } from './ReuseImgTag'
import { Collapsible } from './Collapsible'

const BlockWithReadMoreWapper = styled.div`
  h2 {
    /* color: rgb(15, 33, 55); */
    color: #047cc2;
  }
  p {
    /* color: rgba(52, 61, 72, 0.8); */
    color: #071741;
  }
`
export const BlockWithReadMore = (props: any) => {
  return (
    <BlockWithReadMoreWapper>
      <div className={props.mainContain} id={props.id}>
        <ReUseHTwotag HeadingStyle={props.containHeading} Heading={props.Heading} />
        <div className={props.InternalSection}>
          <div className={props.containImage}>
            <ReuseImgTag ImagePath={props.ImagePath} AltName={props.altName} ImageStyle={props.allImage} />
          </div>
          <div className={props.paraSection}>
            <ReUseHTwotag HeadingStyle={props.containHeadingBig} Heading={props.Heading} />
            <ReUsePtag
              para={props.paraOne}
              paraStyle={props.containParaOne}
              Icon={props.IconOne}
              IconStyle={props.IconColorOne}
            />
            <ReUsePtag
              para={props.paraTwo}
              paraStyle={props.containParaTwo}
              Icon={props.IconTwo}
              IconStyle={props.IconColorTwo}
            />
            <ReUsePtag
              para={props.paraThree}
              paraStyle={props.containParaThree}
              Icon={props.IconThree}
              IconStyle={props.IconColorThree}
            />
            <ReUsePtag
              para={props.paraFour}
              paraStyle={props.containParaFour}
              Icon={props.IconFour}
              IconStyle={props.IconColorFour}
            />
            <ReUsePtag
              para={props.paraFive}
              paraStyle={props.containParaFive}
              Icon={props.IconFive}
              IconStyle={props.IconColorFive}
            />
            <Collapsible ButtonName={props.buttonName} ButtonCenter={props.alignCenter}>
              <ReUsePtag
                para={props.paraSix}
                paraStyle={props.containParaSix}
                Icon={props.IconSix}
                IconStyle={props.IconColorSix}
              />
              <ReUsePtag
                para={props.paraSeven}
                paraStyle={props.containParaSeven}
                Icon={props.IconSeven}
                IconStyle={props.IconColorSeven}
              />
              <ReUsePtag
                para={props.paraEight}
                paraStyle={props.containParaEight}
                Icon={props.IconEight}
                IconStyle={props.IconColorEight}
              />
              <ReUsePtag
                para={props.paraNine}
                paraStyle={props.containParaNine}
                Icon={props.IconNine}
                IconStyle={props.IconColorNine}
              />
              <ReUsePtag
                para={props.paraTen}
                paraStyle={props.containParaTen}
                Icon={props.IconTen}
                IconStyle={props.IconColorTen}
              />
              <ReUsePtag
                para={props.paraEleven}
                paraStyle={props.containParaEleven}
                Icon={props.IconEleven}
                IconStyle={props.IconColorEleven}
              />
              <ReUsePtag
                para={props.paraTwelve}
                paraStyle={props.containParaTwelve}
                Icon={props.IconTwelve}
                IconStyle={props.IconColorTwelve}
              />
              <ReUsePtag
                para={props.paraThirteen}
                paraStyle={props.containParaThirteen}
                Icon={props.IconThirteen}
                IconStyle={props.IconColorThirteen}
              />
            </Collapsible>
          </div>
        </div>
      </div>
    </BlockWithReadMoreWapper>
  )
}
