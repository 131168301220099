import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    Heading: 'Why Now ?',
    paraOne:
      'Every industry is continuously transforming, resulting in managing spend which is more complex and labor intensive. To do nothing would keep on increasing your procurement costs and limit your capabilities in today’s business world.',
    paraTwo:
      'There is an increasing demand on Procurement organizations to  be cost effective. This can only be achieved by modernizing procurement applications that deliver capabilities to reduce transactional workload, promote automation and collaboration between businesses and its suppliers.',
    paraThree:
      'In today’s competitive world modernizing procurement applications gives enterprises a competitive advantage by ensuring productivity and enhancing business operations.',
    icon: <FaArrowCircleRight />,
    ImagePath: 'https://storage.googleapis.com/raindroppublic/website_data/Why-Raindrop.png',
    AltName: 'Modern Procurement Applications',
  },
]
