import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    Title: 'Raindrop is the Answer to Your Procurement Digitization Challenges',
    ParaPonitOne: 'Fulfills all procurement business processes',
    ParaPonitTwo: 'Provides a 360 degree view of supplier spend, contracts, and risk',
    ParaPonitThree:
      'Transitions your organization up the value chain from being transactionally focused to predictive.',
    ParaPonitFour:
      'Provides the Procurement team with futuristic capabilities of Artificial Intelligence and Machine Learning, automation and workflows that improve your business operations.',
    ParaPonitFive: 'Seamlessly integrates and interfaces with your existing and other financial/supplier systems',
    ParaPonitSix:
      'Raindrop adheres to all of your security and compliance needs while providing multi-language and multi-currency capabilities.',
    icon: <FaArrowCircleRight />,
    ImagePath: 'https://storage.googleapis.com/raindroppublic/website_data/Procurement-Digitization-Challenges.png',
    AltName: 'Spend Management Technology ',
    buttonName: 'Read More',
  },
]
