import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    Heading: 'Leading by Experience',
    paraOne:
      'We know Procurement. We live it. We breathe it. From planning, to sourcing, to contract execution, to supplier management, we have lived in your shoes for decades.',
    paraTwo: 'As Procurement professionals, we saw gaps in current Procurement software and knew we could do better.',
    paraThree: ' We wanted to provide answers to the challenges we continue to face.',
    Icon: <FaArrowCircleRight />,
    ImagePath: 'https://storage.googleapis.com/raindroppublic/website_data/climb.svg',
    AltName: 'Procurement Software',
    ParaPonitOne: 'Why does it take months to implement my procurement software?',
    ParaPonitTwo:
      'My business partners are asking me to deliver savings to committed spend. How do I translate the ask into an Operation Plan?',
    ParaPonitThree:
      'How do I create a singular plan as a guiding light for my Procurement team to follow and visibly deliver quantifiable value back to the business?',
    ParaPonitFour: 'Why are almost all current procurement software solutions so transactionally focused?',
    ParaPonitFive:
      'Why doesn’t my current procurement software collaborate with my business partners and suppliers seamlessly?',
    ParaPonitSix:
      'Why do I have to navigate across several enterprise systems to understand what contracts have been signed, when they renew, how much is my contractual obligation, and how much I am spending?',
    ParaPonitSeven: 'Why do I have to chase down contracts for execution post sourcing?',
    ParaPonitEight:
      'Why don’t I know which supplier contracts contain an annual uplift which impacts our budget forecasts?',
    ParaPonitNine: 'Why I am constantly surprised by suppliers telling me our contracts have expired or auto renewed?',
    ParaPonitTen:
      'Why does Procurement, Legal, and internal stakeholders all have their own technology solution with no ‘single source of truth’?',
    buttonName: 'Read More',
  },
]
