import React from 'react'
import styled from 'styled-components'
import { Block } from './Block'
import WhyNowData from '../Data/WhyNowData'

const AppwhyNowWapper = styled.div`
  padding: 1rem 2rem 1rem 2rem;
  @media (max-width: 1090px) {
    padding: 1rem 1rem 1rem 1rem;
  }
  .mainContain {
    max-width: 1920px;
    margin: auto;
  }
  .InternalSection {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1090px) {
      flex-flow: wrap;
    }
  }
  .containHeadingSmall {
    display: none;
    @media (max-width: 1090px) {
      text-align: center;
      display: block;
      padding-bottom: 3%;
    }
  }
  .containHeadingBig {
    margin: auto;
    padding-bottom: 3%;
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .whyNowPara {
    max-width: 950px;
    padding: 15px 35px 15px 0px;
    @media (max-width: 1391px) {
      max-width: 700px;
      padding: 15px 35px 15px 0px;
    }
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
    }
  }
  .whyNowImage {
    height: auto;
    width: 51%;
    @media (max-width: 1391px) {
      width: 60%;
    }
    @media (max-width: 1090px) {
      width: 100%;
    }
  }
  .whyNowImageCenter {
    width: 95%;
    @media (max-width: 1090px) {
      width: 100%;
      margin: auto;
    }
  }
  .paraSection {
    margin-top: 1%;
    margin: auto;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .buttonDisplay {
    display: none;
  }
`

export const AppwhyNow = () => {
  return (
    <AppwhyNowWapper>
      {WhyNowData.map((data, index) => {
        return (
          <div key={index}>
            <Block
              Heading={data.Heading}
              paraOne={data.paraOne}
              paraTwo={data.paraTwo}
              paraThree={data.paraThree}
              ImagePath={data.ImagePath}
              IconTwo={data.icon}
              IconThree={data.icon}
              altName={data.AltName}
              mainContain="mainContain"
              containHeading="containHeadingSmall"
              containHeadingBig="containHeadingBig"
              containParaOne="whyNowPara"
              containParaTwo="whyNowPara"
              containParaThree="whyNowPara"
              InternalSection="InternalSection"
              containImage="whyNowImage"
              paraSection="paraSection"
              IconColorTwo="iconColor"
              IconColorThree="iconColor"
              IconColorOne="buttonDisplay"
              IconColorFour="buttonDisplay"
              IconColorFive="buttonDisplay"
              containParaFive="buttonDisplay"
              containParaFour="buttonDisplay"
              ButtonDisplay="buttonDisplay"
              allImage="whyNowImageCenter"
            />
          </div>
        )
      })}
    </AppwhyNowWapper>
  )
}
