import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    Title: 'Raindrop is Designed by Procurement Professionals',
    para: 'Finally, real people who really understand what I need. We took our decades of hands-on Procurement industry experience to create a platform that is simple and immediate to provision, fits any size business, and is so easy to use with a truly frictionless user experience. This is Raindrop.',
    ImagePath: 'https://storage.googleapis.com/raindroppublic/website_data/Designed-by-procurement.png',
    AltName: 'Enterprise procurement software',
    Icon: <FaArrowCircleRight />,
    buttonName: 'Read More',
    ParaPonitOne: 'A platform which only takes literal minutes to provision and gives you instant value addition.',
    ParaSubPonitOne:
      'Ability to load your offline spreadsheets and provide spend insights and begin your sourcing activities',
    ParaPonitTwo:
      'A platform which provides capabilities to make your sourcing predictive and supplier management proactive.',
    ParaSubPonitTwo:
      'Proactively shows the Procurement team the saving opportunities and risk associated through commodity Machine Learning',
    ParaSubPonitThree: 'Effectively collaborates with your business partners and suppliers',
    ParaPonitThree: 'A Platform that provides a total pipeline view of contractual spend and new spend.',
    ParaSubPonitFour:
      'Helping Procurement teams to prioritize their engagements, identify and eliminate bottlenecks, and automate business processes fulfillment.',
  },
]
