import React from 'react'
import styled from 'styled-components'
import LeadingbyExperienceData from '../Data/LeadingbyExperienceData'
import { BlockWithReadMore } from './BlockWithReadMore'

const LeadingbyExperienceWpper = styled.div`
  max-width: 1920px;
  margin: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  .LeadingContain {
    max-width: 1920px;
    margin: auto;
  }
  .LeadingSection {
    display: flex;
    justify-content: space-around;
    @media (max-width: 1090px) {
      flex-flow: wrap;
    }
  }
  .leadingHeadingSmall {
    display: none;
    @media (max-width: 1090px) {
      text-align: center;
      display: block;
      padding-bottom: 3%;
    }
  }
  .leadingHeadingBig {
    padding: 0px 0px 3% 70px;
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .leadingImage {
    height: auto;
    width: 55vw;
    order: 1;
    @media (max-width: 1090px) {
      order: 0;
      width: auto;
    }
  }
  .LeadingImageCenter {
    width: 90%;
    display: flex;
    float: right;
    @media (max-width: 1090px) {
      float: unset;
      width: 100%;
    }
  }
  .leadingParaSection {
    order: 0;
    margin-top: 2%;
    @media (max-width: 1920px) and (min-width: 1820px) {
      margin-top: 4%;
    }
    @media (max-width: 1090px) {
      order: 1;
    }
  }
  .LeadingParaUpper {
    max-width: 825px;
    padding: 15px 15px 15px 70px;
    @media (max-width: 1650px) {
      max-width: 825px;
      padding: 15px 0px 15px 70px;
    }
    @media (max-width: 1090px) {
      padding: 15px 15px 15px 15px;
      margin: auto;
    }
  }
  .LeadingPara {
    max-width: 825px;
    padding: 15px 15px 15px 70px;
    @media (max-width: 1650px) {
      max-width: 730px;
      padding: 15px 80px 15px 70px;
    }
    @media (max-width: 1090px) {
      padding: 15px 15px 15px 15px;
      margin: auto;
    }
  }
  .LeadingParaButton {
    max-width: auto;
    padding: 15px 100px 15px 70px;
    @media (max-width: 1090px) {
      max-width: 825px;
      padding: 15px 15px 15px 15px;
      margin: auto;
    }
  }
  .LeadingParaButtonOne {
    max-width: auto;
    padding: 25px 100px 15px 70px;
    @media (max-width: 1090px) {
      max-width: 825px;
      padding: 15px 15px 15px 15px;
      margin: auto;
    }
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .PonitPara {
    margin-top: 0%;
  }
  .IconDisplay {
    display: none;
  }
  .LeadingButton {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
`

export const AppLeadingbyExperience = () => {
  return (
    <LeadingbyExperienceWpper>
      {LeadingbyExperienceData.map((data, index) => {
        return (
          <div key={index}>
            <BlockWithReadMore
              Heading={data.Heading}
              paraOne={data.paraOne}
              paraTwo={data.paraTwo}
              paraThree={data.paraThree}
              paraFour={data.ParaPonitOne}
              paraFive={data.ParaPonitTwo}
              paraSix={data.ParaPonitThree}
              paraSeven={data.ParaPonitFour}
              paraEight={data.ParaPonitFive}
              paraNine={data.ParaPonitSix}
              paraTen={data.ParaPonitSeven}
              paraEleven={data.ParaPonitEight}
              paraTwelve={data.ParaPonitNine}
              paraThirteen={data.ParaPonitTen}
              ImagePath={data.ImagePath}
              IconFour={data.Icon}
              IconFive={data.Icon}
              IconSix={data.Icon}
              IconSeven={data.Icon}
              IconEight={data.Icon}
              IconNine={data.Icon}
              IconTen={data.Icon}
              IconEleven={data.Icon}
              IconTwelve={data.Icon}
              IconThirteen={data.Icon}
              altName={data.AltName}
              buttonName={data.buttonName}
              mainContain="LeadingContain"
              containHeading="leadingHeadingSmall"
              containHeadingBig="leadingHeadingBig"
              containParaOne="LeadingParaUpper"
              containParaTwo="LeadingParaUpper"
              containParaThree="LeadingParaUpper"
              containParaFour="LeadingParaUpper"
              containParaFive="LeadingParaUpper"
              containParaSix="LeadingParaUpper"
              containParaSeven="LeadingParaUpper"
              containParaEight="LeadingParaUpper"
              containParaNine="LeadingParaUpper"
              containParaTen="LeadingParaUpper"
              containParaEleven="LeadingParaUpper"
              containParaTwelve="LeadingParaUpper"
              containParaThirteen="LeadingParaUpper"
              InternalSection="LeadingSection"
              containImage="leadingImage"
              IconColorOne="IconDisplay"
              IconColorTwo="IconDisplay"
              IconColorThree="IconDisplay"
              IconColorFour="iconColor"
              IconColorFive="iconColor"
              IconColorSix="iconColor"
              IconColorSeven="iconColor"
              IconColorEight="iconColor"
              IconColorNine="iconColor"
              IconColorTen="iconColor"
              IconColorEleven="iconColor"
              IconColorTwelve="iconColor"
              IconColorThirteen="iconColor"
              paraSection="leadingParaSection"
              allImage="LeadingImageCenter"
              alignCenter="LeadingButton"
            />
          </div>
        )
      })}
    </LeadingbyExperienceWpper>
  )
}
