import React from 'react'
import styled from 'styled-components'
import { BlockWithReadMore } from './BlockWithReadMore'
import RaindropIsDesignedData from '../Data/RaindropIsDesignedData'

const AppRaindropIsDesignedWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  padding-top: 5rem;
  padding-bottom: 1rem;
  .DesignedMainContain {
    max-width: 1920px;
    margin: auto;
  }
  .DesignedInternalSection {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1090px) {
      flex-flow: wrap;
    }
  }
  .DesignedHeadingSmall {
    display: none;
    @media (max-width: 1090px) {
      text-align: center;
      display: block;
      padding-bottom: 3%;
    }
  }
  .containHeadingBig {
    margin: auto;
    padding-bottom: 3%;
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .DesignedPara {
    max-width: 950px;
    padding: 15px 35px 15px 0px;
    @media (max-width: 1391px) {
      max-width: 700px;
      padding: 15px 15px 15px 15px;
    }
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
      margin: auto;
    }
  }
  .DesignedParaPonit {
    max-width: 950px;
    padding: 15px 35px 15px 0px;
    @media (max-width: 1920px) and (min-width: 1830px) {
      padding: 15px 0px 15px 0px;
    }
    @media (max-width: 1760px) and (min-width: 1671px) {
      padding: 15px 20px 15px 10px;
    }
    @media (max-width: 1670px) and (min-width: 1551px) {
      padding: 15px 20px 15px 50px;
    }
    @media (max-width: 1550px) and (min-width: 1461px) {
      padding: 15px 10px 15px 80px;
    }
    @media (max-width: 1460px) and (min-width: 1392px) {
      padding: 15px 10px 15px 140px;
    }
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
      margin: auto;
    }
  }
  .DesignedParaPonitSub {
    max-width: 950px;
    padding: 15px 35px 15px 0px;
    @media (max-width: 1920px) and (min-width: 1830px) {
      padding: 15px 0px 15px 0px;
    }
    @media (max-width: 1760px) and (min-width: 1671px) {
      padding: 15px 20px 15px 10px;
    }
    @media (max-width: 1670px) and (min-width: 1551px) {
      padding: 15px 20px 15px 40px;
    }
    @media (max-width: 1550px) and (min-width: 1461px) {
      padding: 15px 10px 15px 80px;
    }
    @media (max-width: 1460px) and (min-width: 1392px) {
      padding: 15px 10px 15px 140px;
    }
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
      margin: auto;
    }
  }
  .DesignedParaNone {
    display: none;
  }
  .IconNone {
    display: none;
  }
  .IconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .DesignedImage {
    height: auto;
    width: 55%;
    @media (max-width: 1391px) {
      width: 70%;
    }
    @media (max-width: 1090px) {
      width: 100%;
      margin: auto;
    }
  }
  .whyNowImageCenter {
    width: 100%;
    @media (max-width: 1090px) {
      width: 100%;
      margin: auto;
    }
  }
  .DesignedparaSection {
    margin: 0% auto;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .buttonDisplay {
    display: none;
  }
  .DesignedPonitSection {
    max-width: 950px;
    margin-left: auto;
    padding: 0px 0px 15px 0px;
    margin-right: 3rem;
    margin-top: -19rem;
    @media (max-width: 1920px) and (min-width: 1830px) {
      margin-right: 3rem;
      margin-top: -21rem;
    }
    @media (max-width: 1831px) and (min-width: 1761px) {
      margin-right: 1rem;
      margin-top: -19rem;
    }
    @media (max-width: 1760px) and (min-width: 1651px) {
      margin-right: 0rem;
      margin-top: -17rem;
    }
    @media (max-width: 1650px) and (min-width: 1534px) {
      margin-top: -14rem;
      margin-right: 1rem;
    }
    @media (max-width: 1533px) and (min-width: 1392px) {
      margin-top: -11rem;
    }
    @media (max-width: 1455px) and (min-width: 1392px) {
      margin-top: -9rem;
    }
    @media (max-width: 1090px) {
      margin-right: auto;
      margin-top: auto;
    }
  }

  .DesignedButton {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
`

export const AppRaindropIsDesigned = () => {
  return (
    <AppRaindropIsDesignedWapper>
      {RaindropIsDesignedData.map((data, index) => {
        return (
          <div key={index}>
            <BlockWithReadMore
              Heading={data.Title}
              paraOne={data.para}
              paraTwo={data.ParaPonitOne}
              paraThree={data.ParaSubPonitOne}
              paraFour={data.ParaPonitTwo}
              paraSix={data.ParaSubPonitTwo}
              paraSeven={data.ParaSubPonitThree}
              paraEight={data.ParaPonitThree}
              paraNine={data.ParaSubPonitFour}
              ImagePath={data.ImagePath}
              IconThree={data.Icon}
              IconSix={data.Icon}
              IconSeven={data.Icon}
              IconNine={data.Icon}
              altName={data.AltName}
              IconColorThree="IconColor"
              mainContain="DesignedMainContain"
              containHeading="DesignedHeadingSmall"
              containHeadingBig="containHeadingBig"
              containParaOne="DesignedPara"
              containParaTwo="DesignedPara"
              containParaThree="DesignedPara"
              containParaFour="DesignedPara"
              containParaSix="DesignedPara"
              containParaSeven="DesignedPara"
              containParaEight="DesignedPara"
              containParaNine="DesignedPara"
              containParaFive="DesignedParaNone"
              containParaTen="DesignedParaNone"
              containParaEleven="DesignedParaNone"
              containParaTwelve="DesignedParaNone"
              containParaThirteen="DesignedParaNone"
              InternalSection="DesignedInternalSection"
              containImage="DesignedImage"
              paraSection="DesignedparaSection"
              IconColor="iconColor"
              IconColorSix="iconColor"
              IconColorSeven="iconColor"
              IconColorNine="iconColor"
              ButtonDisplay="buttonDisplay"
              allImage="whyNowImageCenter"
              alignCenter="DesignedButton"
            />
          </div>
        )
      })}
    </AppRaindropIsDesignedWapper>
  )
}
