import React from 'react'
import styled from 'styled-components'
import DigitizationData from '../Data/DigitizationData'
import { BlockWithReadMore } from './BlockWithReadMore'

const AppDigitizationWpper = styled.div`
  max-width: 1920px;
  margin: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  .DigitizationContain {
    max-width: 1920px;
    margin: auto;
  }
  .DigitizationSection {
    display: flex;
    justify-content: space-around;
    @media (max-width: 1090px) {
      flex-flow: wrap;
    }
  }
  .DigitizationHeadingSmall {
    display: none;
    @media (max-width: 1090px) {
      text-align: center;
      display: block;
      padding-bottom: 3%;
    }
  }
  .DigitizationHeadingBig {
    padding: 0px 0px 3% 70px;
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .DigitizationImage {
    height: auto;
    order: 1;
    @media (max-width: 1090px) {
      order: 0;
    }
  }
  .DigitizationImageCenter {
    width: 90%;
    display: flex;
    float: right;
    @media (max-width: 1090px) {
      width: 100%;
    }
  }
  .DigitizationParaSection {
    order: 0;
    margin-top: 2%;
    @media (max-width: 1920px) and (min-width: 1820px) {
      margin-top: 4%;
    }
    @media (max-width: 1090px) {
      order: 1;
    }
  }
  .DigitizationParaPonitSection {
    margin-top: -15%;
    @media (max-width: 1650px) {
      margin-top: -13%;
    }
    @media (max-width: 1500px) {
      margin-top: -9%;
    }
    @media (max-width: 1090px) {
      margin: auto;
    }
  }
  .DigitizationParaOne {
    display: none;
  }
  /* .DigitizationPara {
    max-width: 925px;
    padding: 15px 0px 15px 70px;
    @media (max-width: 1650px) {
      max-width: 750px;
    }
    @media (max-width: 1090px) {
      padding: 15px 15px 15px 15px;
      margin: auto;
      max-width: 750px;
    }
  } */
  .DigitizationParaUpper {
    max-width: 925px;
    padding: 15px 0px 15px 70px;

    @media (max-width: 1090px) {
      padding: 15px 15px 15px 15px;
      margin: auto;
      max-width: 750px;
    }
  }
  .LeadingParaButton {
    max-width: auto;
    padding: 15px 100px 15px 70px;
    @media (max-width: 1090px) {
      max-width: 825px;
      padding: 15px 15px 15px 15px;
      margin: auto;
    }
  }
  .LeadingParaButtonOne {
    max-width: auto;
    padding: 25px 100px 15px 70px;
    @media (max-width: 1090px) {
      max-width: 825px;
      padding: 15px 15px 15px 15px;
      margin: auto;
    }
  }

  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .DigitizationButton {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
`

export const AppDigitization = () => {
  return (
    <AppDigitizationWpper>
      {DigitizationData.map((data, index) => {
        return (
          <div key={index}>
            <BlockWithReadMore
              Heading={data.Title}
              paraTwo={data.ParaPonitOne}
              paraThree={data.ParaPonitTwo}
              paraFour={data.ParaPonitThree}
              paraFive={data.ParaPonitFour}
              paraSix={data.ParaPonitFive}
              paraSeven={data.ParaPonitSix}
              ImagePath={data.ImagePath}
              IconTwo={data.icon}
              IconThree={data.icon}
              IconFour={data.icon}
              IconFive={data.icon}
              IconSix={data.icon}
              IconSeven={data.icon}
              altName={data.AltName}
              mainContain="DigitizationContain"
              containHeading="DigitizationHeadingSmall"
              containHeadingBig="DigitizationHeadingBig"
              containParaOne="DigitizationParaOne"
              containParaTwo="DigitizationParaUpper"
              containParaThree="DigitizationParaUpper"
              containParaFour="DigitizationParaUpper"
              containParaFive="DigitizationParaUpper"
              containParaSix="DigitizationParaUpper"
              containParaSeven="DigitizationParaUpper"
              InternalSection="DigitizationSection"
              containImage="DigitizationImage"
              IconColorTwo="iconColor"
              IconColorThree="iconColor"
              IconColorFour="iconColor"
              IconColorFive="iconColor"
              IconColorSix="iconColor"
              IconColorSeven="iconColor"
              paraSection="DigitizationParaSection"
              allImage="DigitizationImageCenter"
              alignCenter="DigitizationButton"
            />
          </div>
        )
      })}
    </AppDigitizationWpper>
  )
}
